var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-container',{staticStyle:{"padding-top":"10px"},attrs:{"fluid":""}},[_c('v-card',{attrs:{"color":"white"}},[_c('v-layout',{attrs:{"row":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"title"},[_vm._v("Unallocated Colleges List")])],1),_c('v-divider',{staticClass:"my-2",attrs:{"vertical":""}}),_c('v-text-field',{staticClass:"mt-0",attrs:{"flat":"","hide-details":"","label":"Search","prepend-inner-icon":"search","single-line":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"pagination":_vm.pagination,"loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.getPVUnAllocCollSubList},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.index + 1))]),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.college_code)+" - "+_vm._s(props.item.college_name)+" ")]),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.subject_code)+" - "+_vm._s(props.item.subject_name)+" ")]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(props.item.student_count))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(props.item.district_name))]),_c('td',{staticClass:"justify-center text-xs-center"},[_c('v-autocomplete',{staticClass:"mt-3 ml-2 mr-2",attrs:{"items":_vm.getPVCollList,"item-text":(item) =>
                  item.college_code +
                  ' - ' +
                  item.college_name +
                  '( Student Count :' +
                  item.student_count +
                  ') ',"item-value":"college_code","color":"blue-grey ","label":"Select Center","prepend-icon":"account_balance","disabled":_vm.ifCenterUser || _vm.publishState == 1},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",attrs:{"close":"","selected":data.selected},on:{"input":function($event){_vm.addedCenter = ''}}},[_vm._v(" "+_vm._s(data.item.college_code + " - " + data.item.college_name + " ( Student Count :" + data.item.student_count + ") ")+" ")])]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c('v-list-tile-content',{domProps:{"textContent":_vm._s(data.item)}})]:[_c('v-list-tile-content',[_c('v-list-tile-title',{domProps:{"innerHTML":_vm._s(
                        data.item.college_code +
                          ' - ' +
                          data.item.college_name +
                          ' ( Student Count :' +
                          data.item.student_count +
                          ') '
                      )}})],1)]]}}],null,true),model:{value:(props.item.center_college_code),callback:function ($$v) {_vm.$set(props.item, "center_college_code", $$v)},expression:"props.item.center_college_code"}})],1)]}}])})],1),_c('v-flex',{attrs:{"xs12":"","mt-4":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":"","row":"","fill-height":""}},[_c('v-btn',{attrs:{"large":"","round":"","color":"primary","dark":_vm.publishState == 1 && _vm.publishState != 1,"disabled":_vm.ifCenterUser || _vm.publishState == 1},on:{"click":function($event){return _vm.addCollToCenter()}}},[_vm._v(" ADD "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("send")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }